/* start old pagination styles */
.tiptapV1 {
  border: 0 !important;
  font-size: 13.333px;
  color: #000;
  font-family: "Times New Roman", Times, serif;
  font-weight: 500;
}

.Page {
  margin: 0 auto;
  background-color: white;
  transform: translate(0, 0);
  margin-top: 0.25rem;
  margin-bottom: 15px;
  position: relative;
  box-sizing: border-box;
  user-select: none !important;
  width: 816px;
  height: 1056px;
}

.Page:first-of-type {
  margin-top: 0;
}

.PageContent {
  user-select: text !important;
}

.PageFooter {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 12px;
  width: 100%;
  height: 46px;
  box-sizing: border-box;
  user-select: none !important;
}
/* end old pagination styles */

/* start new pagination styles */
.tiptap-container {
  position: relative;
  width: 816px;
  box-sizing: border-box;
  margin-bottom: 100px;
}

.tiptapV2 {
  border: 0 !important;
  font-size: 13.333px;
  color: #000;
  font-family: "Times New Roman", Times, serif;
  font-weight: 500;
  position: relative;
  z-index: 1;
  background-color: white;
  width: 816px;
  height: auto;
  padding: 46px;
  padding-left: 45px;
  min-height: calc(1056px - 46px); /* Minimum height adjusted for first page */
  box-sizing: border-box;
}

.tiptapV2 p::before {
  content: '\00a0';
  visibility: hidden;
  display: inline-block;
  height: 0;
  width: 1px;
}

.page-break {
  position: relative;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  float: left;
  clear: both;
  box-sizing: border-box;
  user-select: none !important;
  pointer-events: none !important;
  unicode-bidi: isolate;
}

.page-break-top {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background-color: #fff;
  font-family: "Times New Roman", Times, serif;
  color: #000;
}

.page-break-middle {
  height: 15px;
  background-color: rgb(248, 250, 248);
}

.page-break-bottom {
  height: 46px;
  background-color: #fff;
}

.page {
  position: relative;
  float: left;
  clear: both;
  margin-top: 964px;
}

.page:first-of-type {
  margin-top: 1010px;
}

.node-table {
  display: inline-block;
  width: 100%;
}
/* end new pagination styles */

.ProseMirror:focus {
  outline: none;
}

.tiptap p {
  line-height: 1.5;
  margin-bottom: 0px;
  word-spacing: normal;
  letter-spacing: normal;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.text-huge {
  font-size: 32px;
}

.text-large {
  font-size: 18px;
}

.text-small {
  font-size: 10px;
}

/* Important: adding or changing styles of node types can impact pagination logic */
.text-editor, .tiptapV2 {
  h1,h2,h3,h4,h5,h6{
    margin: 0;
  }
  max-width: 100%;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

ol li:has(strong)::marker {
  font-weight: bold;
}

ol li:has(em)::marker {
  font-style: italic;
}

.comment-highlight {
  display: inline; /* Keep it inline to avoid block-level spacing issues */
  position: relative;
  mix-blend-mode: multiply;
}

.comment-highlight::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #FFF1A9;
}

.comment-highlight[data-selected="true"] {
  background: #FFE76F;
}

.lb-root {
  --lb-accent: #398B88 !important;
}

.lb-portal {
  z-index: 2000 !important;
}

.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  font-style: normal;
  font-weight: 500;
  left: -1px;
  line-height: normal;
  position: absolute;
  user-select: none;
  white-space: nowrap;
  font-size: 12px;
  color: #fff;
  top: -1.4em;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  padding: 2px 6px;
  pointer-events: none;
}

.highlighted-text {
  padding: 0 !important;
}

/* Blacklining styles */
[data-diff-type="inline-insert"],
[data-diff-type="block-insert"] {
  color: var(--black);
  background-color: #99D1C7;
  position: relative;

  &::after {
    background-color: #99D1C7;
    bottom: -2px;
    content: "";
    left: -2px;
    position: absolute;
    right: -2px;
    top: -2px;
    z-index: -1;
  }
}

[data-diff-type="inline-delete"],
[data-diff-type="block-delete"] {
  color: var(--black);
  background-color: rgb(255, 226, 226);
  text-decoration: line-through;
  position: relative;

  &::after {
    background-color: rgb(255, 226, 226);
    bottom: -2px;
    content: "";
    left: -2px;
    position: absolute;
    right: -2px;
    top: -2px;
    z-index: -1;
  }
}

/* Custom diff style for table insert */
[data-diff-type="block-insert"] > .node-table {
  border: 6px solid #99D1C7;
  position: relative;
}

[data-diff-type="block-insert"] > .node-table::after {
  background-color: #99D1C7;
  border: 6px solid #99D1C7;
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  content: "";
  z-index: -1;
}

/* Custom diff style for table delete */
[data-diff-type="block-delete"] > .node-table {
  border: 6px solid rgb(255, 226, 226);
  position: relative;
}

[data-diff-type="block-delete"] > .node-table::after {
  background-color: rgb(255, 226, 226);
  border: 6px solid rgb(255, 226, 226);
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  content: "";
  z-index: -1;
}

/* Custom diff style for inner block-update delete and insert for table structural changes */
/* Commented out because it causes the tables to scroll
/*
[data-diff-type="block-update"] [data-diff-type="block-insert"] {
  border: 6px solid #99D1C7;
  position: relative;
}

[data-diff-type="block-update"] [data-diff-type="block-insert"]::after {
  background-color: #99D1C7;
  border: 6px solid #99D1C7;
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  content: "";
  z-index: -1;
}

[data-diff-type="block-update"] [data-diff-type="block-delete"] {
  border: 6px solid rgb(255, 226, 226);
  position: relative;
}

[data-diff-type="block-update"] [data-diff-type="block-delete"]::after {
  background-color: rgb(255, 226, 226);
  border: 6px solid rgb(255, 226, 226);
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  content: "";
  z-index: -1;
}
*/

@media print {
  /* start old pagination print styles */
  .Page, .AutoPage {
    page-break-after: always;
    width: 816px;
    height: 1056px;
    margin: 0;
    overflow: hidden;
    position: relative;
  }

  .Page.landscape {
    width: 1056px;
    height: 816px;
    transform: rotate(-90deg) translate(-100%, 0);
    transform-origin: top left;
  }

  .Page {
    border: 1px solid #fff;
  }

  .PageContent {
    border: 1px solid #fff;
  }

  .Page.landscape .PageFooter {
    position: absolute;
    top: 770px; /* 816px - 46px footer height */
    right: 0;
    transform: rotate(90deg) translateY(100%, 0);
    transform-origin: top right;
    width: 1056px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    box-sizing: border-box;
  }

  .PageFooter {
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 12px;
    width: 100%;
    height: 46px;
  }
  /* end old pagination print styles */

  /* start new paginatin print styles */
  @page {
    size: 816px 1056px;
  }

  .page-break-top {
    page-break-after: always
  }

  .page-break-middle {
    display: none !important;
  }

  .page-break-bottom {
    page-break-inside: avoid;
  }
  /* end new pagination print styles */

  /* hide highlighted text due to comments on print */
  .comment-highlight::after {
    content: none !important;
  }

  .comment-highlight {
    padding: 0 !important;
    background: none !important;
  }

  .linked-text {
    border: none !important;
    border-bottom: none !important;
  }

  /* hide table delete button on print */
  button {
    display: none !important;
  }

  .collaboration-cursor__caret {
    display: none !important;
  }

  /*
  .highlighted-text {
    background-color: transparent !important;
    color: inherit !important;
  }
  */
}
