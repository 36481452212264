/*.App {*/
/*  padding: 0;*/
/*}*/

/*.Header {*/
/*  border-bottom: 1px solid #ddd;*/
/*}*/

/*.Sidebar {*/
/*  width: 200px;*/
/*  margin: 5px;*/
/*  position: sticky;*/
/*  top: 62px;*/
/*  align-self: flex-start;*/
/*  align-items: start;*/
/*  fill: #1D1B20*/
/*}*/

/*.Sidebar .nav-item {*/
/*  width: 100%;*/
/*}*/

/*.Sidebar a,*/
/*.dropdown-item,*/
/*.dropdown-item.active {*/
/*  color: #444;*/
/*}*/

/*.Sidebar a:hover {*/
/*  background-color: #eee;*/
/*}*/

/*.Sidebar a:visited {*/
/*  color: #444;*/
/*}*/

/*.Sidebar .nav-item .active,*/
/*.dropdown-item.active {*/
/*  background-color: #def;*/
/*}*/

/*.Content {*/
/*  margin-top: 50px;*/
/*}*/

/*.Post {*/
/*  align-items: start;*/
/*  padding-top: 5px;*/
/*  border-bottom: 1px solid #eee;*/
/*}*/

/*.Post:hover {*/
/*  background-color: #f8f8f8;*/
/*}*/

/*.Post a {*/
/*  color: #14c;*/
/*  text-decoration: none;*/
/*}*/

/*.Post a:visited {*/
/*  color: #14c;*/
/*}*/

/*.More {*/
/*  margin-top: 10px;*/
/*  margin-bottom: 10px;*/
/*  text-align: right;*/
/*}*/

/*.InputField {*/
/*  margin-top: 15px;*/
/*  margin-bottom: 15px;*/
/*}*/

/*.Write {*/
/*  margin-bottom: 10px;*/
/*  padding: 30px 0px 40px 0px;*/
/*  border-bottom: 1px solid #eee;*/
/*}*/

/*.Write form {*/
/*  width: 100%;*/
/*}*/

/*.financial-table {*/
/*  width: 100%;*/
/*  border-collapse: collapse;*/
/*  margin-top: 20px;*/
/*}*/

/*.financial-table th,*/
/*.financial-table td {*/
/*  padding: 2px 15px;*/
/*  text-align: left;*/
/*  white-space: pre-wrap;*/
/*  max-width: 400px;*/
/*  !* border-bottom: 1px solid #ddd; *!*/
/*  overflow: hidden;*/
/*  !* Hide overflowing content *!*/
/*}*/

/*.financial-table td::first-line {*/
/*  text-indent: 20px;*/
/*  !* Adjust the indent as needed *!*/
/*}*/

/*.financial-table th {*/
/*  font-weight: bold;*/
/*}*/

/*.financial-table td {*/
/*  font-family: monospace;*/
/*}*/

/*.financial-table tbody tr:nth-child(odd) {*/
/*  background-color: #cceeff;*/
/*}*/

/*.financial-table tbody tr:hover {*/
/*  background-color: #E1E8ED;*/
/*}*/

/*.financial-table td.number-cell {*/
/*  text-align: right;*/
/*  padding-right: 15px;*/
/*}*/

/*.gridjs-th-content {*/
/*  display: none;*/
/*}*/
